import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import LocalPhoneTwoToneIcon from '@material-ui/icons/LocalPhoneTwoTone';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import './About.css';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#cceded',
    width: '100%',
    paddingTop: '6px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#00a6a6',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  content: {
    flexGrow: 1,
    padding: '12px',
    paddingTop: '0px',
    paddingBottom: '72px',
  },
  dropdowncontent: {
    display: 'block',
  },
  dropdowncontent1: {
    display: 'block',
    padding: '0px',
    marginLeft: '0px',
  },
  contactbutton: {
    width: '100%',
  },
  contactcopy: {
    padding: '20px',
    marginLeft: '10px',
  },
  ptag: {
  marginTop: '10px',
  marginBottom: '10px',
  },
  button: {

  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  backgroundColor: '#cceded',
  width: '100%',
}));


export default function ContentAdrea() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  function copyStringToClipboard(string) {
    let textarea;
    let result;
  
    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = string;
  
      document.body.appendChild(textarea);
  
      textarea.focus();
      textarea.select();
  
      const range = document.createRange();
      range.selectNodeContents(textarea);
  
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
  
      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
      document.body.classList.add("copyalert");
    setTimeout(() => {
      document.body.classList.remove("copyalert")
    }, 3000);
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
  
    // manual copy fallback using prompt
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    return true;
  };


  /*function copyStringToClipboard(str) {
    // Temporäres Element erzeugen
    var el = document.createElement('textarea');
    // Den zu kopierenden String dem Element zuweisen
    el.value = str;
    // Element nicht editierbar setzen und aus dem Fenster schieben
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Text innerhalb des Elements auswählen
    el.select();
    // Ausgewählten Text in die Zwischenablage kopieren
    document.execCommand('copy');
    // Temporäres Element löschen
    document.body.removeChild(el);
    document.body.classList.add("copyalert");
    setTimeout(() => {
      document.body.classList.remove("copyalert")
    }, 3000);
 };
*/
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Über die App
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <div className={classes.root}>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Allgemein</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.dropdowncontent}>
        <Typography>
            Mit dieser App können Sie bequem an den Befragungen der Konsumgüterforschung teilnehmen.
            Diese Anwendung lässt sich als Web-App lokal auf einem oder mehreren Geräten speichern. Alternativ können Sie diese App im Browser Ihres Computers nutzen. Auf dem Reiter Einstellungen können Sie die PNUM ändern sowie ein zweites Kind mittels einer weiteren PNUM hinzufügen.
            Für die Teilnahme an den Befragungen ist eine Internet-Verbindung erforderlich (Wlan oder mobile Daten).
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Kontakt</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.dropdowncontent1}>
        <Typography className="dropdowncontenttext">
          
            Sie haben Fragen oder Probleme? Zögern Sie nicht und melden Sie sich bei uns! So erreichen Sie die Konsumgüterforschung:
          </Typography>
        <List>
        <Divider component="li" />
      <ListItem>
      <Button button component="a" target="_blank" href="tel:+496196893434" className={classes.contactbutton}>
        <ListItemAvatar>
            <LocalPhoneTwoToneIcon className="contacticon" />
        </ListItemAvatar>
        <ListItemText primary="Telefon" secondary="06196-89-3434" />
        </Button>
        <Divider orientation="vertical" flexItem />
        <IconButton aria-label="phone" className={classes.contactcopy} onClick={() => copyStringToClipboard('+496196893434')}>
        <FileCopyTwoToneIcon />
      </IconButton>
      </ListItem>
      <Divider component="li" />
      <ListItem>
      <Button button component="a" target="_blank" href="tel:+496196893636" className={classes.contactbutton}>
        <ListItemAvatar>
            <LocalPhoneTwoToneIcon className="contacticon" />
        </ListItemAvatar>
        <ListItemText primary="Telefon" secondary="06196-89-3636" />
        </Button>
        <Divider orientation="vertical" flexItem />
        <IconButton aria-label="phone" className={classes.contactcopy} onClick={() => copyStringToClipboard('+496196893636')}>
        <FileCopyTwoToneIcon />
      </IconButton>
      </ListItem>
      <Divider component="li" />

      <ListItem>
      <Button button component="a" target="_blank" href="mailto:Konsumgueterswa.im@pg.com" className={classes.contactbutton}>
        <ListItemAvatar>
            <MailTwoToneIcon className="contacticon" />
        </ListItemAvatar>
        <ListItemText primary="E-Mail" secondary="Konsumgueterswa.im@pg.com" />
        </Button>
        <Divider orientation="vertical" flexItem />
        <IconButton aria-label="mail" className={classes.contactcopy} onClick={() => copyStringToClipboard('Konsumgueterswa.im@pg.com')}>
        <FileCopyTwoToneIcon />
      </IconButton>
      </ListItem>

      <div className="alertmsg-copy">
      <Alert severity="success">Daten wurden in die Zwischenablage kopiert.</Alert>
      </div>
    </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
      <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Öffnungszeiten</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          Mo - Do: 8:00 - 15:30<br />Fr: 8:00 - 13:30<br />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
      <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Impressum</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          Konsumgüterforschung<br />
          Procter & Gamble Service GmbH<br />
          Sulzbacher Str. 40<br />
          65824 Schwalbach am Taunus<br /><br />
          Telefon: <Typography component="a" target="_blank" href="tel:+496196893434">06196-89-3434</Typography><br />
          E-Mail: <Typography component="a" target="_blank" href="mailto:Konsumgueterswa.im@pg.com">konsumgue&shy;terswa.im@&shy;pg.com</Typography><br /><br />
          Aufsichtsratsvorsitzender:<br />Heinz-Joachim Schultner<br /><br />
          Geschäftsführer: <br /> Janis Bauer, Bettina Buschhoff, Gabriele Hässig, Franz-Olaf Kallerhoff,
          Ivan Liron, Stefan Schamberg, Astrid Teckentrup, Matthias Weber<br /><br />
          Sitz:<br /> Schwalbach am Taunus<br /><br />Amtsgericht:<br />Königstein im Taunus HRB 6593<br /><br />
          Ust.ID: DE 293 480 388
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
      <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.heading}>Datenschutz</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          Auf unserer Webseite können Sie unsere Datenschutzrichtlinien im Detail nachlesen:<br /><br />
          <Button button component="a" target="_blank" href="https://www.pg.com/privacy/german/privacy_statement.shtml" className={classes.contactbutton} variant="contained" color="primary">
          Zu den Datenschutzrichtlinien
         </Button>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
      <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.heading}>Version</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          1.0.2<br />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      
    </div>

      </main>
    </div>
  );
}
